module.exports = {
  "name": "wucu_de",
  "prefix": "",
  "logo": "/images/wundercurves_logo.png",
  "siteName": "Wundercurves.de",
  "siteUrl": "https://www.wundercurves.de",
  "canonicalUrl": "https://www.wundercurves.de",
  "modules": {
    "klaviyo": {
      "listId": "W4QV6x",
      "footerSubscriptionIntent": "footer_subscribe"
    },
    "appsync": {
      "apiEndpoint": "https://fav.wundercurves.de/graphql",
      "apiKey": "da2-erihkt22ovfk3odsaobufun35a"
    },
    "cloudinary": {
      "imageFetchUrl": "https://res-3.cloudinary.com/wucu/image/fetch/",
      "imageTransformations": {
        "pdp": {
          "thumbnail": "w_78,h_117,fl_lossy,c_pad",
          "mainImg": {
            "MOBILE_M": "w_500,h_715,fl_lossy,c_fill",
            "MOBILE_L": "w_500,h_715,fl_lossy,c_fill",
            "TABLET": "w_500,h_715,fl_lossy,c_fill",
            "LAPTOP": "w_500,h_715,fl_lossy,c_fill",
            "LAPTOP_L": "w_500,h_715,fl_lossy,c_fill",
            "LAPTOP_XL": "w_500,h_715,fl_lossy,c_fill"
          }
        },
        "productwidget": {
          "MOBILE_M": "w_356,h_534,fl_lossy,c_pad",
          "MOBILE_L": "w_356,h_534,fl_lossy,c_pad",
          "TABLET": "w_356,h_534,fl_lossy,c_pad",
          "LAPTOP": "w_356,h_534,fl_lossy,c_pad",
          "LAPTOP_L": "w_356,h_534,fl_lossy,c_pad",
          "LAPTOP_XL": "w_356,h_534,fl_lossy,c_pad"
        }
      },
      "blacklistedMerchants": [
        "Ulla Popken",
        "LaShoe",
        "H&M"
      ]
    },
    "feedburner": {
      "feed_address": "https://feeds.feedburner.com/wundercurvesde",
      "title": "Dein Mode-Magazin für mittlere/große Größen",
      "description": "Dein Plus-Size-Magazin mit vielen Mode-Tipps & Inspirationen für mittlere/große Größen. Wir helfen Dir, Deine schönen Kurven am besten zur Geltung zu bringen!",
      "copyright": "© 2024 Kaminrunde & Freunde GmbH",
      "ttl": "60"
    },
    "googlemaps": {
      "apiKey": "AIzaSyAl60n7p07HYQK6lVilAe_ggwbBJFktNw8"
    },
    "mailchimp": {
      "wishlist": {
        "listId": "334a15d4fb",
        "uId": "525fc1b7f9388e77e44405337",
        "subscribeApiEndpoint": "https://wundercurves.us11.list-manage.com/subscribe/post-json",
        "unsubscribeUrl": "https://wundercurves.us11.list-manage.com/unsubscribe"
      },
      "newsletter": {
        "listId": "ea332191b1",
        "uId": "525fc1b7f9388e77e44405337",
        "subscribeApiEndpoint": "https://wundercurves.us11.list-manage.com/subscribe/post"
      }
    },
    "shopify": {
      "shopName": "wundercurves",
      "domain": "my.wundercurves.de",
      "storefrontAccessToken": "a0f12338873fc67d0e78aab2b833c4d5"
    },
    "auth0": {
      "domain": "wundercurves.eu.auth0.com",
      "clientId": "zGL57vpfmUhdEStnovoO2CRBd9r3M9Vf"
    },
    "squarelovin": {
      "apiKey": "592441e63ee073370139abdb",
      "accessToken": "592441e63ee073370139abdb.ba5e41dc4e4bb4f3cc644b376"
    },
    "typesense": {
      "host": "8ti2j0fxas5lygznp-1.a1.typesense.net",
      "port": 443,
      "protocol": "https",
      "apiKey": "smkDSjyGFy7Ix6sUsQTQ2S5ZSwHOyv28",
      "connectionTimeoutSeconds": 60
    },
    "posthog": {
      "apiKey": "phc_W0ctsv9ErUVW2glZ88YN2ykek1U0zgVIeaTSaykVmVe",
      "apiHost": "https://www.wundercurves.de/phingest",
      "head": true,
      "isEnabledDevMode": true,
      "initOptions": {
        "persistence": "localStorage+cookie",
        "ui_host": "https://eu.posthog.com"
      }
    }
  },
  "indexes": {
    "products": "products_wundercurvesde_live",
    "products_price_asc": "products_productPrice_asc",
    "products_inventory": "products_inventory"
  },
  "sortingAttributes": {
    "default": "",
    "priceAsc": "productPrice:asc",
    "priceDesc": "productPrice:desc"
  },
  "blacklists": {
    "noCloudinaryImageFetchMerchants": [],
    "noDirectbuyClickoutLinkMerchants": [
      "Wundercurves",
      "Favie",
      "Vevina"
    ]
  },
  "features": {
    "directbuyClickout": true,
    "wishlist": false,
    "subscription": false,
    "onesignal": true,
    "tagFilters": true,
    "zendesk": false,
    "maleCategory": false,
    "cookiebot": false,
    "cookiepro": true,
    "pdpStory": false,
    "directbuyOnlyProducts": false,
    "appleBadgeBanner": false,
    "googleBadgeBanner": false,
    "posthog": true,
    "posthogDistinctIdClickoutUrlParam": false,
    "structuredData": true
  },
  "apiEndpoints": {
    "merchantClickout": "https://api.wundercurves.de/wucu/t",
    "directbuyClickout": "https://api.wundercurves.de/wucu/groupedid",
    "contentfulPreview": "https://a0x4ckdkvh.execute-api.eu-central-1.amazonaws.com/dev/cfpreview/wucu",
    "initialNavigation": "https://s3.eu-central-1.amazonaws.com/wucu-initialstate-dev/HeaderMainNavigation.json"
  },
  "checkout": {
    "baseUrl": "https://my.wundercurves.de",
    "enabled": false,
    "loginUrl": "https://my.wundercurves.de/account/login",
    "accountUrl": "https://my.wundercurves.de/account",
    "shippingPrice": 4.9,
    "shippingConditionsUrl": "/page/faq#direktkaufshopping",
    "tenantId": "777563754445",
    "defaultInventoryQuantityRemainder": 3,
    "merchantInventoryQuantityRemainder": {
      "1": 3,
      "119": 1,
      "180": 5,
      "181": 1,
      "213": 2,
      "312": 3,
      "374": 1,
      "435": 1,
      "450": 2,
      "451": 2,
      "452": 1,
      "453": 1,
      "454": 1,
      "455": 1,
      "501": 1,
      "503": 1,
      "505": 1,
      "507": 1,
      "509": 1,
      "511": 1,
      "513": 1,
      "515": 1,
      "517": 1,
      "519": 1,
      "521": 1,
      "523": 1,
      "525": 1,
      "527": 1,
      "529": 1,
      "531": 1,
      "532": 1,
      "534": 1,
      "536": 1,
      "538": 1,
      "540": 1,
      "542": 1,
      "544": 1,
      "546": 1,
      "548": 1,
      "550": 1
    }
  },
  "classificationDataDict": {
    "materialComposition": "Material",
    "careInstructions": "Pflegehinweise"
  },
  "storyContentOnlyPages": [
    {
      "objectID": "5rpi0Ylghh94MxiWuU2zqx",
      "targetFilePath": "/page-docs/widerrufsrecht/"
    },
    {
      "objectID": "5h4EGh4yc1agquYRuRQXgQ",
      "targetFilePath": "/page-docs/zahlung-und-versand/"
    },
    {
      "objectID": "4K7PpaUWAgqKSwwMEeUoYA",
      "targetFilePath": "/page-docs/datenschutz/"
    },
    {
      "objectID": "6LK18dlDF9OcvpBjdrPxRc",
      "targetFilePath": "/page-docs/nutzungsbedingungen/"
    },
    {
      "objectID": "5XOMhxBXUck8ecGyGCo0Yi",
      "targetFilePath": "/page-docs/impressum/"
    }
  ],
  "categories": {
    "5xg6rKgIfuuq8iEAOMsymu": {
      "urlKey": "/shop/bekleidung/pullover-und-strick",
      "categoryPath": "Pullover & Strick"
    },
    "32tD5SXSykAUGUs84ESaSk": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/sweatshirts",
      "categoryPath": "Pullover & Strick > Sweatshirts"
    },
    "226yMKcS9GyAQSO8Ai0CsQ": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/pullover",
      "categoryPath": "Pullover & Strick > Pullover"
    },
    "5VkCu7lZrGyw2ysgsoG0mQ": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/strickjacken-cardigans",
      "categoryPath": "Pullover & Strick > Strickjacken Cardigans"
    },
    "4dnwnMLy86o29qXNfGYFvf": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/strickjacken-cardigans/long-cardigans",
      "categoryPath": "Pullover & Strick > Strickjacken Cardigans > Long Cardigans"
    },
    "GPtID0Fq46QScyy8o0eou": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/boleros",
      "categoryPath": "Pullover & Strick > Boleros"
    },
    "6vXdI0YQkjPxbhhkmU3hGO": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/long-cardigans",
      "categoryPath": "Pullover & Strick > Long Cardigans"
    },
    "185ZdeY5kEC6eAmsgu6yIu": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/fleece",
      "categoryPath": "Pullover & Strick > Fleece"
    },
    "12ktmZaACmQMQUu6Mg00Ug": {
      "urlKey": "/shop/bekleidung/pullover-und-strick/sweatjacken",
      "categoryPath": "Pullover & Strick > Sweatjacken"
    },
    "5bp7Ihu8a4CwkeOA428a4C": {
      "urlKey": "/shop/bekleidung/kleider",
      "categoryPath": "Kleider"
    },
    "6o2ZCben8AiUi0eY2kseI4": {
      "urlKey": "/shop/bekleidung/kleider/abendkleider",
      "categoryPath": "Kleider > Abendkleider"
    },
    "6EnrdhCGD6UKaQWcAeAqqe": {
      "urlKey": "/shop/bekleidung/kleider/strick-und-jerseykleider",
      "categoryPath": "Kleider > Strick- & Jerseykleider"
    },
    "2qGhr6XyP6sM68qMmqAcW2": {
      "urlKey": "/shop/bekleidung/kleider/maxikleider",
      "categoryPath": "Kleider > Maxikleider"
    },
    "4QDqU4AvPjuLIBcgMhDRK1": {
      "urlKey": "/shop/bekleidung/kleider/wickelkleider",
      "categoryPath": "Kleider > Wickelkleider"
    },
    "UEeMmVRjawIE0ESu6wEkM": {
      "urlKey": "/shop/bekleidung/kleider/sommerkleider",
      "categoryPath": "Kleider > Sommerkleider"
    },
    "4Z41GH7oj6SsmouEcWgcA6": {
      "urlKey": "/shop/bekleidung/kleider/midikleider",
      "categoryPath": "Kleider > Midikleider"
    },
    "6iPNWVqNK8ioIqmE6Myi4g": {
      "urlKey": "/shop/bekleidung/kleider/cocktailkleider",
      "categoryPath": "Kleider > Cocktailkleider"
    },
    "5Kl4eTfIVaOcKkoa8KkwkI": {
      "urlKey": "/shop/bekleidung/kleider/jeanskleider",
      "categoryPath": "Kleider > Jeanskleider"
    },
    "fTQWXnwQQ80WAsSO0eGW0": {
      "urlKey": "/shop/bekleidung/kleider/etuikleider",
      "categoryPath": "Kleider > Etuikleider"
    },
    "2A87RnZ5jAvVkhyA2rjF9D": {
      "urlKey": "/shop/bekleidung/kleider/businesskleider",
      "categoryPath": "Kleider > Businesskleider"
    },
    "6PetO4tx7iak0iqcGK8k4E": {
      "urlKey": "/shop/bekleidung/hosen",
      "categoryPath": "Hosen"
    },
    "1t29HlviJW2iGg0O2i84i6": {
      "urlKey": "/shop/bekleidung/hosen/schlupfhosen",
      "categoryPath": "Hosen > Schlupfhosen"
    },
    "2VP6Y3s97iGsacC2MWIu6i": {
      "urlKey": "/shop/bekleidung/hosen/leggings",
      "categoryPath": "Hosen > Leggings"
    },
    "2kzGjnKcn6ee424O0KgWSw": {
      "urlKey": "/shop/bekleidung/hosen/jumpsuits-und-overalls",
      "categoryPath": "Hosen > Jumpsuits & Overalls"
    },
    "6uqtGvDYRiEyQ6CKkieioQ": {
      "urlKey": "/shop/bekleidung/hosen/jogginghosen",
      "categoryPath": "Hosen > Jogginghosen"
    },
    "4SPWReCbVS4yegMao4kKCw": {
      "urlKey": "/shop/bekleidung/hosen/palazzo-hosen",
      "categoryPath": "Hosen > Palazzo Hosen"
    },
    "4GNTUFbFwQwCsKgK2ck6a4": {
      "urlKey": "/shop/bekleidung/hosen/stoffhosen",
      "categoryPath": "Hosen > Stoffhosen"
    },
    "zFwdB5Bh3UYc8OYyGm0qU": {
      "urlKey": "/shop/bekleidung/hosen/shorts",
      "categoryPath": "Hosen > Shorts"
    },
    "2j0gwse9aGAv4Rx4nNZTsB": {
      "urlKey": "/shop/bekleidung/hosen/leinenhosen",
      "categoryPath": "Hosen > Leinenhosen"
    },
    "6OiEnYvbxKOauaQQ6CyK4M": {
      "urlKey": "/shop/bekleidung/hosen/culottes",
      "categoryPath": "Hosen > Culottes"
    },
    "4pjiOJrChWaIwoueYQSsoE": {
      "urlKey": "/shop/bekleidung/hosen/chinos",
      "categoryPath": "Hosen > Chinos"
    },
    "5mZfauAIYSZ3X0vjeNCXrh": {
      "urlKey": "/shop/bekleidung/hosen/7-8-hosen-und-caprihosen",
      "categoryPath": "Hosen > 7∕8 Hosen und Caprihosen"
    },
    "6ngWjP7ksg4aAKGYIuKWMA": {
      "urlKey": "/shop/bekleidung/hosen/businesshosen",
      "categoryPath": "Hosen > Businesshosen"
    },
    "2F6f8lUMEEme6mGguiWW0M": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung",
      "categoryPath": "Bademode & Strandkleidung"
    },
    "3dU2Okh4Q0UakmA2QAo0SK": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung/bikinis",
      "categoryPath": "Bademode & Strandkleidung > Bikinis"
    },
    "5sbxig0DWZW8mJh14BIjqT": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung/badeanzuege",
      "categoryPath": "Bademode & Strandkleidung > Badeanzüge"
    },
    "5byB2PiJwc2CqAyQ0sGgoK": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung/tankinis",
      "categoryPath": "Bademode & Strandkleidung > Tankinis"
    },
    "5Jus1Xf3kqiqLS2wmMoe1": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung/badekleider",
      "categoryPath": "Bademode & Strandkleidung > Badekleider"
    },
    "1i71H3kwJ0IgQKwmSukOwo": {
      "urlKey": "/shop/bekleidung/bademode-und-strandkleidung/strandbekleidung",
      "categoryPath": "Bademode & Strandkleidung > Strandbekleidung"
    },
    "5j1g3WjMg8swMI2geQWcE8": {
      "urlKey": "/shop/bekleidung/blusen-und-tuniken",
      "categoryPath": "Blusen & Tuniken"
    },
    "3Oc1yvWwWIIWUaeOgI2muS": {
      "urlKey": "/shop/bekleidung/blusen-und-tuniken/longblusen",
      "categoryPath": "Blusen & Tuniken > Longblusen"
    },
    "1WVjLkXLc8CygEGmoAUywY": {
      "urlKey": "/shop/bekleidung/blusen-und-tuniken/hemdblusen",
      "categoryPath": "Blusen & Tuniken > Hemdblusen"
    },
    "2rfQDG54JCGa66kiWQwgWy": {
      "urlKey": "/shop/bekleidung/blusen-und-tuniken/tuniken",
      "categoryPath": "Blusen & Tuniken > Tuniken"
    },
    "5pEJsKIkqkuiyO0A6GoWAQ": {
      "urlKey": "/shop/bekleidung/blusen-und-tuniken/blusen",
      "categoryPath": "Blusen & Tuniken > Blusen"
    },
    "6pMhyfUgkEmswIOeMeKm2K": {
      "urlKey": "/shop/bekleidung/accessoires",
      "categoryPath": "Accessoires"
    },
    "4vK5KGGiMgcck8Oa6MAoWC": {
      "urlKey": "/shop/bekleidung/accessoires/taschen",
      "categoryPath": "Accessoires > Taschen"
    },
    "1eydzyvCocSyi0myiswsoE": {
      "urlKey": "/shop/bekleidung/accessoires/schmuck",
      "categoryPath": "Accessoires > Schmuck"
    },
    "12UQxV8DKQ6Gu80Wo2W26Y": {
      "urlKey": "/shop/bekleidung/accessoires/guertel",
      "categoryPath": "Accessoires > Gürtel"
    },
    "77fDlXq7jUiwe8mgU0O8c8": {
      "urlKey": "/shop/bekleidung/accessoires/schals-und-tuecher",
      "categoryPath": "Accessoires > Schals & Tücher"
    },
    "2I6eLToPzqyeOKmo4EQAUQ": {
      "urlKey": "/shop/bekleidung/accessoires/muetzen-und-huete",
      "categoryPath": "Accessoires > Mützen & Hüte"
    },
    "1JludCip8EqUSs4WWaaGmc": {
      "urlKey": "/shop/bekleidung/accessoires/uhren",
      "categoryPath": "Accessoires > Uhren"
    },
    "1T4skw5e0AGWqqyMCmqUKq": {
      "urlKey": "/shop/bekleidung/accessoires/portemonnaies-und-etuis",
      "categoryPath": "Accessoires > Portemonnaies & Etuis"
    },
    "PCBW1jriuYKecWiyasMOc": {
      "urlKey": "/shop/bekleidung/accessoires/handschuhe",
      "categoryPath": "Accessoires > Handschuhe"
    },
    "2SjNqwjG48o6GuuIOYIKaG": {
      "urlKey": "/shop/bekleidung/accessoires/sonnenbrillen",
      "categoryPath": "Accessoires > Sonnenbrillen"
    },
    "3mCN6ykpuoWqq0e6w8I4G": {
      "urlKey": "/shop/bekleidung/trachtenmode",
      "categoryPath": "Trachtenmode"
    },
    "69fs3ezV84cucKiWuaOqoa": {
      "urlKey": "/shop/bekleidung/trachtenmode/dirndlblusen",
      "categoryPath": "Trachtenmode > Dirndlblusen"
    },
    "6oDt807ShOGOmUog4CIuq8": {
      "urlKey": "/shop/bekleidung/trachtenmode/trachtenjacken",
      "categoryPath": "Trachtenmode > Trachtenjacken"
    },
    "4DCOiVxDxmS2QEyCm2yccu": {
      "urlKey": "/shop/bekleidung/trachtenmode/dirndl",
      "categoryPath": "Trachtenmode > Dirndl"
    },
    "3t4cMj21naW2uuGa4muoak": {
      "urlKey": "/shop/bekleidung/trachtenmode/trachtenshirts",
      "categoryPath": "Trachtenmode > Trachtenshirts"
    },
    "6s0gnqPlIO1IXBjVjCLvEz": {
      "urlKey": "/shop/bekleidung/trachtenmode/trachten-accessoires",
      "categoryPath": "Trachtenmode > Trachten Accessoires"
    },
    "48dBXvgZvymw4MOSys2w2O": {
      "urlKey": "/shop/bekleidung/trachtenmode/trachtenroecke-und-hosen",
      "categoryPath": "Trachtenmode > Trachtenröcke und -hosen"
    },
    "6Rn9tC5DaMoyA862a2802M": {
      "urlKey": "/shop/bekleidung/trachtenmode/dirndlschuerzen",
      "categoryPath": "Trachtenmode > Dirndlschürzen"
    },
    "4bnuSIkIYgaiGEwYIga4Ki": {
      "urlKey": "/shop/bekleidung/trachtenmode/schuhe-fuers-dirndl",
      "categoryPath": "Trachtenmode > Schuhe fürs Dirndl"
    },
    "2fzkRpmMok6uu6amegqWwc": {
      "urlKey": "/shop/bekleidung/strumpfwaren",
      "categoryPath": "Strumpfwaren"
    },
    "3myzcElXkQw0mcM0KaOSc0": {
      "urlKey": "/shop/bekleidung/strumpfwaren/kniestruempfe",
      "categoryPath": "Strumpfwaren > Kniestrümpfe"
    },
    "2hsPcUxOUAGaiM4MsokQ6K": {
      "urlKey": "/shop/bekleidung/strumpfwaren/halterlose-struempfe",
      "categoryPath": "Strumpfwaren > Halterlose Strümpfe"
    },
    "26MEhqo8o0yEsCEiK4OyEE": {
      "urlKey": "/shop/bekleidung/strumpfwaren/strumpfhosen",
      "categoryPath": "Strumpfwaren > Strumpfhosen"
    },
    "X0hUs4scSq8M6iuseausU": {
      "urlKey": "/shop/bekleidung/strumpfwaren/struempfe",
      "categoryPath": "Strumpfwaren > Strümpfe"
    },
    "1dYsFWP48ykAC62A4ayCmy": {
      "urlKey": "/shop/bekleidung/strumpfwaren/kurzsocken",
      "categoryPath": "Strumpfwaren > Kurzsocken"
    },
    "6wr9qI6UqQmWYA8AGyesW0": {
      "urlKey": "/shop/bekleidung/strumpfwaren/sportsocken",
      "categoryPath": "Strumpfwaren > Sportsocken"
    },
    "2RXwX45kbe4aWMuOGC48Q8": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel",
      "categoryPath": "Schuhe & Stiefel"
    },
    "4T0Z7T5zrqIis8GMCkiaSs": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/sneaker-und-sportschuhe",
      "categoryPath": "Schuhe & Stiefel > Sneaker & Sportschuhe"
    },
    "3i6qIWUYGA2w28YE64c8Is": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/stiefeletten",
      "categoryPath": "Schuhe & Stiefel > Stiefeletten"
    },
    "2PkQJGWPfaa4OuMisM8iu2": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/outdoorschuhe",
      "categoryPath": "Schuhe & Stiefel > Outdoorschuhe"
    },
    "55nvJ7SY9iocKUK2AEmkQS": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/stiefel",
      "categoryPath": "Schuhe & Stiefel > Stiefel"
    },
    "2g4J6DP5BKwKwQO04uuu4A": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/ballerinas",
      "categoryPath": "Schuhe & Stiefel > Ballerinas"
    },
    "ibzwG6669GIG4syGOWC20": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/overknee-stiefel",
      "categoryPath": "Schuhe & Stiefel > Overknee Stiefel"
    },
    "2WLaDNNb9Y0OiEW4wGAA4G": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/pumps",
      "categoryPath": "Schuhe & Stiefel > Pumps"
    },
    "6dQGJB7D4btZ8Nrw8HBC8x": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/gummistiefel",
      "categoryPath": "Schuhe & Stiefel > Gummistiefel"
    },
    "3Mpp1DBbgQic2SUkGYqGYM": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/sandalen-und-sandaletten",
      "categoryPath": "Schuhe & Stiefel > Sandalen & Sandaletten"
    },
    "4lK2JjBuxiCSa8CEYyq4g4": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/slipper-und-espadrilles",
      "categoryPath": "Schuhe & Stiefel > Slipper & Espadrilles"
    },
    "6zZeMg5FXUWcCqKCaY0iQu": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/schnuerschuhe",
      "categoryPath": "Schuhe & Stiefel > Schnürschuhe"
    },
    "6KCh9vk9Au2S8NVnArQzuS": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/plateauschuhe",
      "categoryPath": "Schuhe & Stiefel > Plateauschuhe"
    },
    "1DXCGGIOf6uiIGgCc4AMkU": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/high-heels",
      "categoryPath": "Schuhe & Stiefel > High Heels"
    },
    "23yBZyvsuYOEuusU6AiYmG": {
      "urlKey": "/shop/bekleidung/schuhe-und-stiefel/hausschuhe",
      "categoryPath": "Schuhe & Stiefel > Hausschuhe"
    },
    "6nwufcZWa73G4UCPj1CpPT": {
      "urlKey": "/shop/bekleidung/outdoorbekleidung",
      "categoryPath": "Outdoorbekleidung"
    },
    "1iWfVlHCO0o6woAgYUWCiY": {
      "urlKey": "/shop/bekleidung/outdoorbekleidung/outdoorhosen",
      "categoryPath": "Outdoorbekleidung > Outdoorhosen"
    },
    "4LBiu4wo0oKUoWq42iY4SE": {
      "urlKey": "/shop/bekleidung/outdoorbekleidung/outdoorjacken-und-maentel",
      "categoryPath": "Outdoorbekleidung > Outdoorjacken & -mäntel"
    },
    "51dO7ytM5aQoMMC6GIOMWi": {
      "urlKey": "/shop/bekleidung/outdoorbekleidung/softshelljacken",
      "categoryPath": "Outdoorbekleidung > Softshelljacken"
    },
    "42Kk7K8DwciQaic80iYg82": {
      "urlKey": "/shop/bekleidung/roecke",
      "categoryPath": "Röcke"
    },
    "3FW3V4hxL24mmom0Qk2e4Y": {
      "urlKey": "/shop/bekleidung/roecke/jeansroecke",
      "categoryPath": "Röcke > Jeansröcke"
    },
    "6BEQ8WILf2aC2sqMUYOOqu": {
      "urlKey": "/shop/bekleidung/roecke/bleistiftroecke",
      "categoryPath": "Röcke > Bleistiftröcke"
    },
    "4XBneD0mgU6mIIAyMs2G08": {
      "urlKey": "/shop/bekleidung/roecke/lederroecke",
      "categoryPath": "Röcke > Lederröcke"
    },
    "5rbLRiuwTYa8Q0owo6wQwC": {
      "urlKey": "/shop/bekleidung/roecke/midiroecke",
      "categoryPath": "Röcke > Midiröcke"
    },
    "6fA69H8s8M6uG4maOycGaq": {
      "urlKey": "/shop/bekleidung/roecke/maxiroecke",
      "categoryPath": "Röcke > Maxiröcke"
    },
    "c3Wo2cNuLeY0eSUCayqYc": {
      "urlKey": "/shop/bekleidung/roecke/miniroecke",
      "categoryPath": "Röcke > Miniröcke"
    },
    "31FNSmbwmsKycq2uACc6w4": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear",
      "categoryPath": "Wäsche & Shapewear"
    },
    "7kP9vR9CbJpJPK4IzbKptd": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/periodenunterwaesche",
      "categoryPath": "Wäsche & Shapewear > Periodenunterwäsche"
    },
    "62g2sVmhXyoGWSEGAweEUY": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/verfuehrerische-dessous",
      "categoryPath": "Wäsche & Shapewear > Verführerische Dessous"
    },
    "3bzSiQmffO6cMEMaCssOQa": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bhs",
      "categoryPath": "Wäsche & Shapewear > BHs"
    },
    "4zkvKrlUOAiIqqgO6WQc6W": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bhs/entlastungs-bhs",
      "categoryPath": "Wäsche & Shapewear > BHs > Entlastungs-BHs"
    },
    "wcpWzpPpSse6nAn3SdKHE": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bhs/push-up-bhs",
      "categoryPath": "Wäsche & Shapewear > BHs > Push Up BHs"
    },
    "1M1LXVCpcVrRcSMLJSMoAg": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bhs/bustiers",
      "categoryPath": "Wäsche & Shapewear > BHs > Bustiers"
    },
    "3fVFnOAJbGmE6CSY80swqe": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/shapewear",
      "categoryPath": "Wäsche & Shapewear > Shapewear"
    },
    "5D85e2MfURZYxaBvJ6b2H9": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/negligees-und-babydolls",
      "categoryPath": "Wäsche & Shapewear > Negligees & Babydolls"
    },
    "6RxFdJlJduOwgg2yum2Km0": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/slips-strings-und-pants",
      "categoryPath": "Wäsche & Shapewear > Slips, Strings & Pants"
    },
    "39uTtUhsw8AQcGIsWWacU4": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bodies",
      "categoryPath": "Wäsche & Shapewear > Bodies"
    },
    "3qHra5IjWo6UsaOSGoaO0E": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/nachtwaesche",
      "categoryPath": "Wäsche & Shapewear > Nachtwäsche"
    },
    "5y9HfdbmtqkmOIikYY6MyY": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/nachtwaesche/nachthemden",
      "categoryPath": "Wäsche & Shapewear > Nachtwäsche > Nachthemden"
    },
    "46rRvpzQIHbeLlWiquhB8Q": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/nachthemden",
      "categoryPath": "Wäsche & Shapewear > Nachthemden"
    },
    "646CEAqSA08kWw8W0WUe2u": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/unterhemden",
      "categoryPath": "Wäsche & Shapewear > Unterhemden"
    },
    "SJnp7inRi86MYwO2qkqS4": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bh-hemden",
      "categoryPath": "Wäsche & Shapewear > BH Hemden"
    },
    "5ZNlQM9YYwEoSAYosSSmm2": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/homewear-und-casual",
      "categoryPath": "Wäsche & Shapewear > Homewear & Casual"
    },
    "MaEtB2zXc4mGye6Cm6wCw": {
      "urlKey": "/shop/bekleidung/waesche-und-shapewear/bademaentel",
      "categoryPath": "Wäsche & Shapewear > Bademäntel"
    },
    "5ead2fTtoIakGcmkISKiqq": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel",
      "categoryPath": "Jacken & Mäntel"
    },
    "3fNIQJeuzS8cGuA4IGCsAu": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/blazer",
      "categoryPath": "Jacken & Mäntel > Blazer"
    },
    "702BOYSTdMEFbjEOYue49Q": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/steppjacken-und-daunenjacken",
      "categoryPath": "Jacken & Mäntel > Steppjacken & Daunenjacken"
    },
    "3qhvil2aBWEq0yyGwCCU0S": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/maentel",
      "categoryPath": "Jacken & Mäntel > Mäntel"
    },
    "6cFLAZnAhUSGa6qYkEM0CS": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/lederjacken",
      "categoryPath": "Jacken & Mäntel > Lederjacken"
    },
    "5ijQeYriAwcW4gkgK6Q4io": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/westen",
      "categoryPath": "Jacken & Mäntel > Westen"
    },
    "4B70dbXm2QWE88ECiqg4wK": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/regenjacken",
      "categoryPath": "Jacken & Mäntel > Regenjacken"
    },
    "6MdEDm3x3qOusQYeII20iK": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/trenchcoats",
      "categoryPath": "Jacken & Mäntel > Trenchcoats"
    },
    "2xt8pmoaEYE8qm8yU0MOSM": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/jacken",
      "categoryPath": "Jacken & Mäntel > Jacken"
    },
    "25JEqmBNCQayUI48yuQgSu": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/jeansjacken",
      "categoryPath": "Jacken & Mäntel > Jeansjacken"
    },
    "4iXyc3vIK4oUQWmouyMMMi": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/winterjacken",
      "categoryPath": "Jacken & Mäntel > Winterjacken"
    },
    "1YEP0cnivCOEisWoSsGg8w": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/fleecejacken",
      "categoryPath": "Jacken & Mäntel > Fleecejacken"
    },
    "2PnJs5ACmcmug4oMS4OOS2": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/ponchos-und-capes",
      "categoryPath": "Jacken & Mäntel > Ponchos & Capes"
    },
    "5M4vrpI19esSMcGyAG6m0A": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/parkas",
      "categoryPath": "Jacken & Mäntel > Parkas"
    },
    "iTBrVLx5XqCiSEaIsSYuQ": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/blousons",
      "categoryPath": "Jacken & Mäntel > Blousons"
    },
    "3sYD36vfV7cUMZDhn1J6gn": {
      "urlKey": "/shop/bekleidung/jacken-und-maentel/kimonos",
      "categoryPath": "Jacken & Mäntel > Kimonos"
    },
    "5acStuSOVUUsYQumCkIwGQ": {
      "urlKey": "/shop/bekleidung/shirts-und-tops",
      "categoryPath": "Shirts & Tops"
    },
    "2Un1UJj4sUgESMu4Y4OiSs": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/shirts",
      "categoryPath": "Shirts & Tops > Shirts"
    },
    "6S7ZAhI0VuGhz0GKzkQ98s": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/shirtverlaengerung",
      "categoryPath": "Shirts & Tops > Shirtverlängerung"
    },
    "3PNhWuo8cgGEeemaWYA4ws": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/langarmshirts",
      "categoryPath": "Shirts & Tops > Langarmshirts"
    },
    "7phHcqKqliSeIaiUAOy0Y2": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/poloshirts",
      "categoryPath": "Shirts & Tops > Poloshirts"
    },
    "29kEnPWemGwB14GnlXdP32": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/longtops",
      "categoryPath": "Shirts & Tops > Longtops"
    },
    "4u83uO7UXe2eayo2aacsuQ": {
      "urlKey": "/shop/bekleidung/shirts-und-tops/tops",
      "categoryPath": "Shirts & Tops > Tops"
    },
    "mqIvibbv5Qo0c4csSO8Ay": {
      "urlKey": "/shop/bekleidung/umstandsmode",
      "categoryPath": "Umstandsmode"
    },
    "kX5o0Ae7aCsYymQ844CoA": {
      "urlKey": "/shop/bekleidung/umstandsmode/hosen-und-roecke-fuer-schwangere",
      "categoryPath": "Umstandsmode > Hosen und Röcke für Schwangere"
    },
    "2U9RPgBLO36mIwOB2yaMhj": {
      "urlKey": "/shop/bekleidung/umstandsmode/still-bhs",
      "categoryPath": "Umstandsmode > Still-BHs"
    },
    "48ZRZ8B5ssCy0AcoM8Q8uk": {
      "urlKey": "/shop/bekleidung/umstandsmode/bademode-fuer-schwangere",
      "categoryPath": "Umstandsmode > Bademode für Schwangere"
    },
    "62EAHUQXNSSeKeegYKIwaM": {
      "urlKey": "/shop/bekleidung/umstandsmode/umstandskleider",
      "categoryPath": "Umstandsmode > Umstandskleider"
    },
    "5zTT5cMthYmSEMGWgM2O0Y": {
      "urlKey": "/shop/bekleidung/umstandsmode/oberteile-fuer-schwangere",
      "categoryPath": "Umstandsmode > Oberteile für Schwangere"
    },
    "2FHJw2w3TOuIae2ee6kWIm": {
      "urlKey": "/shop/bekleidung/umstandsmode/nachtwaesche-fuer-schwangere",
      "categoryPath": "Umstandsmode > Nachtwäsche für Schwangere"
    },
    "45FoWXagxqKYoUGi4eqSQi": {
      "urlKey": "/shop/bekleidung/umstandsmode/unterwaesche-fuer-schwangere",
      "categoryPath": "Umstandsmode > Unterwäsche für Schwangere"
    },
    "3qi6aKizVugaugc2kI6KCU": {
      "urlKey": "/shop/bekleidung/jeans",
      "categoryPath": "Jeans"
    },
    "W34pc2iAPNoYrUgNv0mZl": {
      "urlKey": "/shop/bekleidung/jeans/mom-jeans",
      "categoryPath": "Jeans > Mom Jeans"
    },
    "CLQozc8QolIoZ8jFSluOO": {
      "urlKey": "/shop/bekleidung/jeans/boyfriend-jeans",
      "categoryPath": "Jeans > Boyfriend Jeans"
    },
    "2V4plbTrZFEtTUMSVH6y9z": {
      "urlKey": "/shop/bekleidung/jeans/7-8-jeans",
      "categoryPath": "Jeans > 7∕8 Jeans"
    },
    "2rP7VTjxhaawCocAoIoquq": {
      "urlKey": "/shop/bekleidung/jeans/bootcut-jeans",
      "categoryPath": "Jeans > Bootcut Jeans"
    },
    "5nRUqrWqWIcoyGioAwG64a": {
      "urlKey": "/shop/bekleidung/jeans/skinny-jeans",
      "categoryPath": "Jeans > Skinny Jeans"
    },
    "7dPqIoogfYZNhYp48zDD2t": {
      "urlKey": "/shop/bekleidung/jeans/slim-fit-jeans",
      "categoryPath": "Jeans > Slim Fit Jeans"
    },
    "1VvXUZD5MMW2akgwimokOS": {
      "urlKey": "/shop/bekleidung/jeans/straight-leg-jeans",
      "categoryPath": "Jeans > Straight Leg Jeans"
    },
    "4ofHut6Q6cCIY00o6uiAmI": {
      "urlKey": "/shop/bekleidung/jeans/weite-jeans",
      "categoryPath": "Jeans > Weite Jeans"
    },
    "4ywiByVkMvlOpLh4Bfsdl1": {
      "urlKey": "/shop/bekleidung/jeans/jeggings",
      "categoryPath": "Jeans > Jeggings"
    },
    "668FDeDwPCSJhKxRzcr1Vw": {
      "urlKey": "/shop/bekleidung/jeans/jeansshorts",
      "categoryPath": "Jeans > Jeansshorts"
    },
    "25uMTeMm2ES2YGuqY0Ge4g": {
      "urlKey": "/shop/bekleidung/sportbekleidung",
      "categoryPath": "Sportbekleidung"
    },
    "1k8LPou8XoyQ8mMwE0KK6g": {
      "urlKey": "/shop/bekleidung/sportbekleidung/sporthosen",
      "categoryPath": "Sportbekleidung > Sporthosen"
    },
    "2ePul1WP3ayUeWy2YMwiak": {
      "urlKey": "/shop/bekleidung/sportbekleidung/funktionsunterwaesche",
      "categoryPath": "Sportbekleidung > Funktionsunterwäsche"
    },
    "rGOK09Bs4KAS4SeeUMSqO": {
      "urlKey": "/shop/bekleidung/sportbekleidung/sportshirts-und-sporttops",
      "categoryPath": "Sportbekleidung > Sportshirts & Sporttops"
    },
    "6lIdnThaTuecsGgIyqiAWm": {
      "urlKey": "/shop/bekleidung/sportbekleidung/sportjacken",
      "categoryPath": "Sportbekleidung > Sportjacken"
    },
    "5OxrXOnqDgpDsxrjSKqrH9": {
      "urlKey": "/shop/bekleidung/sportbekleidung/sport-bhs",
      "categoryPath": "Sportbekleidung > Sport-BHs"
    },
    "HTJ0wz0gmYCQqiwaImYui": {
      "urlKey": "/shop/bekleidung/fanmerchandise",
      "categoryPath": "Fanmerchandise"
    },
    "4gkvmP8cveqcscu8CKQgY8": {
      "urlKey": "/shop/bekleidung/fanmerchandise/merchandise-accessoires",
      "categoryPath": "Fanmerchandise > Merchandise Accessoires"
    },
    "4bz0zpaiQ8kYSekWUYYOOS": {
      "urlKey": "/shop/bekleidung/fanmerchandise/kleidung",
      "categoryPath": "Fanmerchandise > Kleidung"
    },
    "4MfKlUxhbiIQiWu6YGSwUS": {
      "urlKey": "/shop/bekleidung/beauty",
      "categoryPath": "Beauty"
    },
    "4hew4AT46IGUkUwK2YGgq6": {
      "urlKey": "/shop/bekleidung/beauty/wellness",
      "categoryPath": "Beauty > Wellness"
    },
    "226IhNWt6kUKOeoaSuwaqc": {
      "urlKey": "/shop/bekleidung/beauty/parfums",
      "categoryPath": "Beauty > Parfums"
    },
    "3cp8gZ9mXYaWS22MeekIME": {
      "urlKey": "/shop/bekleidung/beauty/nagelpflege",
      "categoryPath": "Beauty > Nagelpflege"
    },
    "4MPBBSdbyUGSs6oUckWAM6": {
      "urlKey": "/shop/bekleidung/beauty/make-up",
      "categoryPath": "Beauty > Make-up"
    },
    "5Jh6RsOPuwckWkm4mKKYM": {
      "urlKey": "/shop/bekleidung/beauty/koerperpflege",
      "categoryPath": "Beauty > Körperpflege"
    },
    "kBzfPqUj840A4EoeCkkMi": {
      "urlKey": "/shop/bekleidung/beauty/haarpflege",
      "categoryPath": "Beauty > Haarpflege"
    },
    "3VV0rKmtEzAmI6C5H5ff1X": {
      "urlKey": "/shop/bekleidung/fuer-den-mann",
      "categoryPath": "Für den Mann"
    },
    "5T8FovyeWZqql5u6IXnCJJ": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/shirts",
      "categoryPath": "Für den Mann > Shirts"
    },
    "3GO2LOURY5DqRmcpSEStbA": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/homewear",
      "categoryPath": "Für den Mann > Homewear"
    },
    "176Ivu3JkEgnMBTCaeACTh": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/jacken-und-maentel",
      "categoryPath": "Für den Mann > Jacken & Mäntel"
    },
    "6Dt2Ug73HOJEfXNq9SsB2j": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/hosen",
      "categoryPath": "Für den Mann > Hosen"
    },
    "6OhUtuQGpPqF6Ntw5e9dAD": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/hemden",
      "categoryPath": "Für den Mann > Hemden"
    },
    "3PPu5Wimjgxb2mxGP91XJz": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/anzuege-und-sakkos",
      "categoryPath": "Für den Mann > Anzüge & Sakkos"
    },
    "6aqF4GK1LiDhJ0BdxcoLEE": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/accessoires",
      "categoryPath": "Für den Mann > Accessoires"
    },
    "2eCLsf6qPqFsIZzpA9PF0R": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/schuhe",
      "categoryPath": "Für den Mann > Schuhe"
    },
    "KAqq1I9BkNdlZUSogVwDH": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/jeans",
      "categoryPath": "Für den Mann > Jeans"
    },
    "6RW3F3XMDU6GYur0Ta7Oxd": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/trachtenmode",
      "categoryPath": "Für den Mann > Trachtenmode"
    },
    "3LhE44XEX8xdh5oCtxqrk8": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/sport-und-outdoor",
      "categoryPath": "Für den Mann > Sport & Outdoor"
    },
    "2ISWjbpZ1pVM7HVMXfRCcH": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/pullover-und-strick",
      "categoryPath": "Für den Mann > Pullover & Strick"
    },
    "1xz5nSo3g8ge7JMHdzmULl": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/maennerpflege",
      "categoryPath": "Für den Mann > Männerpflege"
    },
    "45T94Vpg4V9psRaBVG9BuW": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/bademode",
      "categoryPath": "Für den Mann > Bademode"
    },
    "7rACYSLH1RJUla9XZFclsw": {
      "urlKey": "/shop/bekleidung/fuer-den-mann/waesche",
      "categoryPath": "Für den Mann > Wäsche"
    },
    "5gvKdF9ic0I0IAksACQeeA": {
      "urlKey": "/shop/bekleidung",
      "categoryPath": ""
    }
  }
}